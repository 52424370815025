import { speed } from 'Lib/speed/speed';
import 'imports-loader?wrapper=window!jquery-throttle-debounce'; // eslint-disable-line import/no-extraneous-dependencies, import/no-webpack-loader-syntax
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const sParent = '.search-content';
const sForm = '.search-content__form';
const sInput = '.search-content__input';
const $input = $(sInput);
const sInputItem = '.search-content__item';
const sClose = '.search-content__close';
const $close = $(sClose);
const cCloseActive = 'search-content__close_active';
const sDropDown = '.search-content__drop-down';
const $dropDown = $(sDropDown);
const cDropDownActive = 'search-content__drop-down_active';
const sLoader = '.search-content__loading';
const $loader = $(sLoader);
const cLoaderActive = 'search-content__loading_active';

$dropDown.attr('data-item-num', '-1');

function hideDpor() {
    $dropDown.removeClass(cDropDownActive).html('');
    $input.closest(sParent).find(sLoader).removeClass(cLoaderActive);
}

$close.on('click', function () {
    const $this = $(this);

    $this.closest(sParent).find(sInput).val('');
    hideDpor();
    $this.removeClass(cCloseActive);
});

$(document).click((e) => {
    if ($(e.target).closest(sParent).length) {
        return;
    }

    hideDpor();
    e.stopPropagation();
});

// нажатие на клавиши клавиатуры
$input.on('keyup paste focus', function (e) {
    const $this = $(this);
    const val = $.trim($this.val());

    if (val.length > 2) {
        if (
            e.keyCode !== 40
            && e.keyCode !== 38
            && e.keyCode !== 27
            && e.keyCode !== 13
            && e.keyCode !== 16
            && e.keyCode !== 17
            && e.keyCode !== 18
            && e.keyCode !== 19
            && e.keyCode !== 20
            && e.keyCode !== 33
            && e.keyCode !== 34
            && e.keyCode !== 35
            && e.keyCode !== 36
            && e.keyCode !== 37
            && e.keyCode !== 39
            && e.keyCode !== 44
            && e.keyCode !== 45
            && e.keyCode !== 91
            && e.keyCode !== 144
        ) { // кнопки, не обновляющие список такие как alt и shift
            $this.closest(sParent).find(sLoader).addClass(cLoaderActive);
        }
    }
});

// нажатие на клавиши клавиатуры с задержкой
$input.on('keyup paste focus', $.debounce(speed * 3, function (e) {
    const $this = $(this);
    const $dropDownThis = $this.closest(sParent).find(sDropDown);
    const href = $dropDownThis.attr('data-href');
    const val = $.trim($this.val());
    const valHtml = `<span class="drop-down__finded-word">${val}</span>`;

    if (val?.length > 2 && isNoEmptyString(href)) {
        if (
            e.keyCode !== 40
            && e.keyCode !== 38
            && e.keyCode !== 27
            && e.keyCode !== 13
            && e.keyCode !== 16
            && e.keyCode !== 17
            && e.keyCode !== 18
            && e.keyCode !== 19
            && e.keyCode !== 20
            && e.keyCode !== 33
            && e.keyCode !== 34
            && e.keyCode !== 35
            && e.keyCode !== 36
            && e.keyCode !== 37
            && e.keyCode !== 39
            && e.keyCode !== 44
            && e.keyCode !== 45
            && e.keyCode !== 91
            && e.keyCode !== 144
        ) { // исключая кнопки не обновляющие список такие как alt и shift
            $.post(href, { 'q': val }, (data) => {
                let dataParse = data;

                if (Object.prototype.toString.call(dataParse) === '[object String]') {
                    dataParse = JSON.parse(data);
                }

                $dropDownThis.empty();

                if (Object.prototype.toString.call(dataParse) === '[object Object]' && !(typeof dataParse.STATUS === 'boolean' && !dataParse.STATUS)) {
                    const arrCombined = [];

                    Object.keys(dataParse).forEach((key) => {
                        const arrSection = dataParse[key];

                        arrSection.forEach((item) => {
                            arrCombined.push({
                                'name': item.name,
                                'value': item.value || '',
                                'city': item.city || '',
                                'country': item.country || '',
                            });
                        });
                    });

                    if (arrCombined.length) {
                        $dropDownThis.append('<div class="drop-down drop-down_shadow drop-down_bradius search-content__drop-down-wrapper"></div>');

                        const $dropDownWrapper = $dropDownThis.find('.search-content__drop-down-wrapper');

                        for (let i = 0; i < arrCombined.length; i++) {
                            const resultItem = arrCombined[i].name.replace(val, valHtml);

                            $dropDownThis.addClass(cDropDownActive);

                            $dropDownWrapper.append(`
                                <div class="drop-down__item search-content__item" data-country="${arrCombined[i].country}" data-city="${arrCombined[i].city}" data-value="${arrCombined[i].value}">${resultItem}</div>
                            `);
                        }
                    }
                }

                $loader.removeClass(cLoaderActive);
            });

            $dropDownThis.attr('data-item-num', '-1');
        }
    }
}));

$input.on('keydown', function (e) {
    const $this = $(this);
    const $dropDownThis = $this.closest(sParent).find(sDropDown);
    let dropDownNum = parseInt($dropDownThis.attr('data-item-num'));

    // down key
    if (e.keyCode === 40) {
        if ($dropDownThis.find('.drop-down__item').length !== 0) {
            $dropDownThis.find(sInputItem).removeClass('drop-down__item_active');
            dropDownNum++;
            $dropDownThis.attr('data-item-num', dropDownNum);

            if (dropDownNum > $dropDownThis.find(sInputItem).length - 1) {
                $dropDownThis.attr('data-item-num', '-1');
            } else {
                const $itemActive = $dropDownThis.find(sInputItem).eq(dropDownNum);

                $itemActive.addClass('drop-down__item_active');
                $this.val($.trim($itemActive.text()));
            }
        }

    // up key
    } else if (e.keyCode === 38) {
        if ($dropDownThis.find('.drop-down__item').length !== 0) {
            $dropDownThis.find(sInputItem).removeClass('drop-down__item_active');

            if (dropDownNum > -1) {
                dropDownNum--;
                $dropDownThis.attr('data-item-num', dropDownNum);
            } else {
                dropDownNum = $dropDownThis.find(sInputItem).length - 1;
                $dropDownThis.attr('data-item-num', dropDownNum);
            }

            if (dropDownNum > -1) {
                const $itemActive = $dropDownThis.find(sInputItem).eq(dropDownNum);

                $itemActive.addClass('drop-down__item_active');
                $this.val($.trim($itemActive.text()));
            }
        }

    // esc key
    } else if (e.keyCode === 27) {
        $this.closest(sParent).find(sInput).val($this.attr('data-val'));
        $dropDownThis.removeClass(cDropDownActive);
        $dropDownThis.empty();
        $this.blur();
        $dropDownThis.attr('data-item-num', '-1');
    }
});

$input.on('keyup', function (e) {
    const $this = $(this);
    const $dropDownThis = $this.closest(sParent).find(sDropDown);
    const dropDownNum = parseInt($dropDownThis.attr('data-item-num'));
    const val = $.trim($this.val());

    if (e.keyCode !== 40 && e.keyCode !== 38 && e.keyCode !== 27) {
        $this.attr('data-val', val);
    }

    // down or up key
    if (e.keyCode === 40 || e.keyCode === 38) {
        if ($dropDownThis.find('.drop-down__item').length !== 0 && dropDownNum === -1) {
            $this.val($this.attr('data-val'));
        }
    }

    $this.closest(sParent).find(sClose).toggleClass(cCloseActive, (val.length));
});

$(sForm).on('submit', function (e) {
    if ($(this).closest(sParent).hasClass('search-content_no-submit')) {
        e.preventDefault();
    }
});

// нажатие на пункт выпадающего списка поиска
$(sParent).on('click', sInputItem, function () {
    const $this = $(this);
    const $thisParent = $this.closest(sParent);

    $thisParent.find(sInput).val($.trim($this.text()));
    $thisParent.find(sForm).submit();
    $thisParent.trigger('select.dropDown.searchContent.eksmo', [$this.attr('data-country'), $this.attr('data-city'), $this.attr('data-value')]);
});