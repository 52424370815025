import { speed } from 'Lib/speed/speed';
import 'Vendor/owlcarousel/owl.carousel.min';

const cOwl = 'owl-carousel';

$('.slider-full-width__slider').trigger('destroy.owl.carousel')
    .removeClass(cOwl)
    .addClass(cOwl)
    .owlCarousel({
        smartSpeed: speed * 2,
        items: 1,
        nav: true,
        dots: false,
        loop: true,
        mouseDrag: false,
        navClass: ['slider-full-width__arrow-prev arrow arrow_prev arrow_shadow', 'slider-full-width__arrow-next arrow arrow_next arrow_shadow'],
        navText: ['', ''],
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
    });