import { Popup } from 'Lib/popup/popup';
import { eventSender } from 'Lib/event-sender/event-sender';

function setEventPopup() {
    const popup = new Popup('popup', document.querySelector('.popup-propavshaya__popup'));
    const elsPopup = document.querySelectorAll('.popup-propavshaya__btn');

    elsPopup?.forEach((item) => {
        item.addEventListener('click', () => {
            popup.open();

            eventSender.customDL({
                event: 'eksmo',
                eventAction: 'click',
                eventCategory: $('title').text().trim(),
                eventLabel: 'Попап "Пропавшая" открыт',
            });
        });
    });
}

document.querySelector('.popup-propavshaya__popup-btn')?.addEventListener('click', () => {
    eventSender.customDL({
        event: 'eksmo',
        eventAction: 'click',
        eventCategory: $('title').text().trim(),
        eventLabel: 'Попап "Пропавшая": забрать подарок - кнопка',
    });
});

setEventPopup();
$('body').on('footerLoaded.eksmo', setEventPopup);