import { speed } from 'Lib/speed/speed';
import { setBgPreviewVideo } from '../video/video';

const $videoSlider = $('.video-slider');
const cSliderOn = 'owl-carousel';

$videoSlider.trigger('destroy.owl.carousel')
    .removeClass(cSliderOn)
    .addClass(cSliderOn)
    .owlCarousel({
        smartSpeed: speed,
        dots: false,
        nav: true,
        items: 1,
        slideBy: 1,
        navClass: [
            `video-slider__arrow-prev arrow arrow_prev arrow_shadow`,
            `video-slider__arrow-next arrow arrow_next arrow_shadow`,
        ],
        navText: ['', ''],
        mouseDrag: true,
    });

$videoSlider.on('changed.owl.carousel', (i) => {
    const $items = $('.video-slider__item');

    $items.each(function () {
        const $th = $(this);

        $th.empty();
        setBgPreviewVideo($th);
    });
});