import { eventSender } from 'Lib/event-sender/event-sender';

const sAudioPlay = '.audio-play';
const cPlays = 'audio-play_plays';

function set($audioPlay) {
    if ($audioPlay instanceof jQuery && $audioPlay.length) {
        const audio = new Audio();

        audio.autoplay = false;
        audio.loop = false;
        audio.volume = 1;

        $audioPlay.on('eksmoCallAudioPlayPause', () => {
            audio.pause();
        });

        $audioPlay.on('click', () => {
            const src = $audioPlay.attr('data-src');

            if (src?.length) {
                if (!$audioPlay.hasClass(cPlays)) {
                    if (!audio.src.length) { // Условие для продолжения прослушивания с того момента где была нажата пауза.
                        audio.src = src;
                    }

                    audio.play();
                } else {
                    audio.pause();
                }
            }

            $('body').trigger('audioPlay.eksmo');
        });

        audio.addEventListener('pause', () => {
            $audioPlay.removeClass(cPlays);
        }, false);

        audio.addEventListener('ended', () => {
            $audioPlay.removeClass(cPlays);
        }, false);

        audio.addEventListener('abort', () => {
            $audioPlay.removeClass(cPlays);
        }, false);

        audio.addEventListener('play', () => {
            $audioPlay.addClass(cPlays);
        }, false);
    }
}

$(sAudioPlay).each(function () {
    set($(this));
});

$('body').on('eksmoCallAudioPlaySet', sAudioPlay, (e) => {
    set($(e.target));
});