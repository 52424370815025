import { speed } from 'Lib/speed/speed';
import { onScreen } from 'Lib/on-screen/on-screen';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import 'imports-loader?wrapper=window!jquery-throttle-debounce'; // eslint-disable-line import/no-extraneous-dependencies, import/no-webpack-loader-syntax
import 'Vendor/owlcarousel/owl.carousel.min';

const minSearchLengthWord = 3;
const $body = $('body');
const sParent = '.heroes';
const sSearchInput = '.heroes__search-input';
const cSearchFocused = 'heroes_search-focus';
const sClose = '.heroes__search-close';
const cCloseShowed = 'heroes__search-close_showed';
const sLoader = '.heroes__search-loader';
const cLoaderShowed = 'heroes__search-loader_showed';
const sSearchResult = '.heroes__search-result';
const sSearchResultCatalog = `${sSearchResult} .heroes__catalog`;
const cSearchResulted = 'heroes_search-result';
const sItem = '.heroes__item';
const sSearchResultItem = `${sSearchResult} ${sItem}`;
const $slider = $('.heroes__slider-slider');
const cOwl = 'owl-carousel';
const sHelperItem = '.heroes__item-helper';
const sPic = '.hero__pic';

$body.on('focus', sSearchInput, function () {
    $(this).closest(sParent).addClass(cSearchFocused);
});

$body.on('blur', sSearchInput, function () {
    searchBlur($(this).closest(sParent));
});

// нажатие на клавиши клавиатуры
$body.on('keyup paste', sSearchInput, function (e) {
    const $this = $(this);
    const val = $.trim($this.val());

    if (
        e.keyCode !== 40
        && e.keyCode !== 38
        && e.keyCode !== 27
        && e.keyCode !== 13
        && e.keyCode !== 16
        && e.keyCode !== 17
        && e.keyCode !== 18
        && e.keyCode !== 19
        && e.keyCode !== 20
        && e.keyCode !== 33
        && e.keyCode !== 34
        && e.keyCode !== 35
        && e.keyCode !== 36
        && e.keyCode !== 37
        && e.keyCode !== 39
        && e.keyCode !== 44
        && e.keyCode !== 45
        && e.keyCode !== 91
        && e.keyCode !== 144
    ) { // кнопки, не обновляющие список такие как alt и shift
        const $parent = $this.closest(sParent);

        if ($parent.length) {
            $parent.find(sLoader).toggleClass(cLoaderShowed, val.length > 2);
            $parent.find(sClose).toggleClass(cCloseShowed, !!val.length);
        }
    }
});

// нажатие на клавиши клавиатуры с задержкой
$body.on('keyup paste', sSearchInput, $.debounce(speed, function (e) {
    const $this = $(this);
    const val = $.trim($this.val());

    if (
        e.keyCode !== 40
        && e.keyCode !== 38
        && e.keyCode !== 27
        && e.keyCode !== 13
        && e.keyCode !== 16
        && e.keyCode !== 17
        && e.keyCode !== 18
        && e.keyCode !== 19
        && e.keyCode !== 20
        && e.keyCode !== 33
        && e.keyCode !== 34
        && e.keyCode !== 35
        && e.keyCode !== 36
        && e.keyCode !== 37
        && e.keyCode !== 39
        && e.keyCode !== 44
        && e.keyCode !== 45
        && e.keyCode !== 91
        && e.keyCode !== 144
    ) { // исключая кнопки не обновляющие список такие как alt и shift
        const $parent = $this.closest(sParent);

        if ($parent.length) {
            const arResult = getSearchResult($parent, val);
            const $searchResultCatalog = $parent.find(sSearchResultCatalog);

            if (Array.isArray(arResult) && arResult.length) {
                showSearchResult($parent);

                arResult.forEach(($hero) => {
                    $searchResultCatalog.append($hero);
                });

                loadSrcOrBg($searchResultCatalog.find(sPic));
            } else {
                hideSearchResult($parent);
            }

            $parent.find(sLoader).removeClass(cLoaderShowed);
        }
    }
}));

$body.on('click', sClose, function () {
    clearSearchInput($(this).closest(sParent));
});

$body.on('click', sSearchResultItem, () => {
    $.fancybox.close();
});

$slider.find(sHelperItem).remove();

$slider.trigger('destroy.owl.carousel')
    .removeClass(cOwl)
    .addClass(cOwl)
    .owlCarousel({
        smartSpeed: speed / 2,
        autoWidth: true,
        nav: false,
        dots: false,
        mouseDrag: false,
        navClass: ['heroes__slider-arrow-prev arrow arrow_prev arrow_no-bg', 'heroes__slider-arrow-next arrow arrow_next arrow_no-bg'],
        navText: ['', ''],
        responsive: {
            770: { items: 7, slideBy: 7, autoWidth: false, nav: true },
            1200: { items: 9, slideBy: 9, autoWidth: false, nav: true },
        },
        onInitialized(e) {
            onScreen($(e.target).find(sPic), loadSrcOrBg);
        },
    });

function searchBlur($th) {
    setTimeout(() => {
        $th.removeClass(cSearchFocused);
    }, speed / 3);
}

function clearSearchInput($parent) {
    if ($parent instanceof jQuery && $parent.length) {
        hideSearchResult($parent);
        $parent.find(sClose).removeClass(cCloseShowed);
        $parent.find(sSearchInput).val('');
    }
}

function getSearchResult($thisParent, word) {
    if ($thisParent instanceof jQuery && $thisParent.length && typeof word === 'string') {
        const arResult = [];
        const thisWord = word.trim();

        if (thisWord.length >= minSearchLengthWord) {
            $thisParent.find(sItem).each(function () {
                const $this = $(this);

                if (!$this.closest(sSearchResult).length) {
                    const $name = $this.find('.hero__name').first();

                    if ($name.length) {
                        const name = $name.text();

                        if (name.length && name.toLowerCase().indexOf(thisWord.toLowerCase()) + 1) {
                            arResult.push($this.clone(true));
                        }
                    }
                }
            });
        }

        return arResult;
    }

    return false;
}

function showSearchResult($parent) {
    if ($parent instanceof jQuery && $parent.length) {
        const $searchResultCatalog = $parent.find(sSearchResultCatalog);

        if ($searchResultCatalog.length) {
            $searchResultCatalog.html('');
            $parent.addClass(cSearchResulted);
        }
    }
}

function hideSearchResult($parent) {
    if ($parent instanceof jQuery && $parent.length) {
        const $searchResultCatalog = $parent.find(sSearchResultCatalog);

        if ($searchResultCatalog.length) {
            $parent.removeClass(cSearchResulted);
            $searchResultCatalog.html('');
        }
    }
}