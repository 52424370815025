import 'COMMON';
import { svgSpriteLoader } from 'Lib/svg-sprite-loader/svg-sprite-loader';
import { oLike } from 'Lib/like/like';
import 'Bem/site/menu-left/menu-left';
import 'Lib/js-anchor-link/js-anchor-link';
import 'Bem/site/books-slider/books-slider';
import 'Bem/site/collections-slider/collections-slider';
import 'Bem/site/authors-slider/authors-slider';
import 'Bem/site/spoiler/spoiler';
import 'Bem/site/book/book';
import 'Bem/site/radio/radio';
import 'Bem/site/checkbox/checkbox';
import 'Bem/site/promo/promo';
import 'Bem/site/slider-full-width/slider-full-width';
import 'Bem/site/filter-formats/filter-formats';
import 'Bem/site/heroes/heroes';
import 'Bem/site/finder/finder';
import 'Bem/site/audio-play/audio-play';
import 'Bem/site/book-preview/book-preview';
import 'Bem/site/tabs/tabs';
import 'Bem/site/sale/sale';
import 'Bem/site/sales/sales';
import 'Bem/site/switchbox/switchbox';
import 'Bem/site/sales-book/sales-book';
import 'Bem/site/filter-row/filter-row';
import 'Bem/journal/video/video';
import 'Bem/journal/video-slider/video-slider';
import 'Bem/journal/articles/articles';
import { Reactions } from 'Lib/reactions/reactions';
import 'Bem/site/popup-propavshaya/popup-propavshaya'; // Удалить, когда будет неактуален EKSMORU-4218
import 'Bem/site/search-content/search-content';

const $body = $('body');

$body.on('init.fastViewBook', () => {
    const reactions = new Reactions(document.querySelectorAll('.reactions'));

    reactions.init();
});

oLike.init('.js-book-like, .js-author-like');

$body.on('eksmoPagenavAjax', () => {
    oLike.init('.js-book-like, .js-author-like');
});

svgSpriteLoader('/site/catalog/svg/sprite.svg?v=20180320');