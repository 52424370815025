const sSpoiler1 = '.filter-formats_spoiler-1';
const sSpoiler2 = '.filter-formats_spoiler-2';

$('.filter-formats__link_spoiler').on('click', toggleSpoiler);

function toggleSpoiler(e) {
    if ($(e.target).closest(sSpoiler1).length) {
        $(sSpoiler1).hide();
        $(sSpoiler2).show();
    } else if ($(e.target).closest(sSpoiler2).length) {
        $(sSpoiler2).hide();
        $(sSpoiler1).show();
    }
}